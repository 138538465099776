<script setup lang="ts">
import { ref } from 'vue'
import { pluralize } from '/~/utils/format/string'
import BaseButton from '/~/components/base/button/base-button.vue'

type IsAvailableCallback = () => Promise<boolean>

const props = defineProps<{
  checkTimeouts: Array<number>
  isAvailableCallback: IsAvailableCallback
}>()

const emit = defineEmits<{
  (event: 'available'): void
}>()

const timeOuts = ref(props.checkTimeouts)
let timeOutsCounter = 0
let timer = ref(timeOuts.value[timeOutsCounter])

const loading = ref(false)

const doCheck = async function (force: boolean) {
  let isAvailable

  if (force) {
    timeOutsCounter = 0
  }

  timer.value = timer.value > 0 ? timer.value - 1 : 0

  if (loading.value) {
    return
  }

  loading.value = true

  if (force || timer.value === 0) {
    try {
      isAvailable = await props.isAvailableCallback()
    } catch (err) {
      isAvailable = false
    }

    if (!isAvailable) {
      timeOutsCounter =
        timeOutsCounter + 1 < timeOuts.value.length
          ? timeOutsCounter + 1
          : timeOutsCounter
      timer.value = timeOuts.value[timeOutsCounter]
    }
  }

  if (isAvailable) {
    clearInterval(setIntervalId)
    timer.value = 0
    emit('available')
  }

  loading.value = false
}

const setIntervalId = setInterval(doCheck, 1000)
</script>

<template>
  <div class="mt-11 p-5 text-center font-sans">
    <h1 class="text-3xl leading-none sm:text-5xl">We’ll be back soon!</h1>
    <div class="m-auto mt-8 max-w-4xl text-2xl text-eonx-neutral-600">
      <p>
        Sorry for the inconvenience but we’re performing some maintenance at the
        moment. If you need to you can always
        <a
          class="text-orange-400 hover:text-eonx-neutral-600"
          href="mailto:help@ewallet.com.au"
          target="_blank"
        >
          contact us
        </a>
        , otherwise we’ll be back online shortly!
      </p>
      <h2 class="mt-6">
        <span v-if="loading || timer === 0">Checking...</span>
        <template v-else>
          <div data-testid="next-check">
            Next check in {{ timer }} {{ pluralize(timer, 'second') }}
          </div>
          <base-button
            data-testid="button-check"
            look="outlined"
            class="mt-6"
            @click="doCheck(true)"
          >
            Check now
          </base-button>
        </template>
      </h2>
    </div>
  </div>
</template>
